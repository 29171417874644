import { render, staticRenderFns } from "./lead-post-preview.vue?vue&type=template&id=adadb1ae&"
import script from "./lead-post-preview.vue?vue&type=script&lang=js&"
export * from "./lead-post-preview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
import KLink from '@web/components/core/k-link'
installComponents(component, {KButton,KLink})
