<template>
  <div>
    <router-link :to="to" class="tw-no-underline">
      <div class="tw-relative tw-w-full tw-overflow-hidden tw-rounded-sm">
        <category-chip :category="post.category" class="tw-absolute tw-m-2 tw-z-10" />
        <div class="tw-w-full tw-aspect-ratio-1:1">
          <img
            :src="post.featuredImage"
            @error="$event.target.src = 'https://via.placeholder.com/100?text=+'"
            class="tw-object-cover tw-object-center tw-rounded-md"
          />
        </div>
      </div>
    </router-link>

    <div class="tw-relative tw-mt-6">
      <router-link
        :to="to"
        class="tw-heading-5 tw-text-black tw-font-bold tw-no-underline hover:tw-underline tw-inline-block"
      >
        {{ postGetAttr(post, 'title', locale) }}
      </router-link>

      <router-link :to="to" class="tw-mt-6 tw-text-black tw-font-bold tw-underline tw-inline-block">
        {{ $t('blog.actions.read') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import CategoryChip from './category-chip'
import { getRouteLocale } from '@web/constants/language'
import { postGetAttr } from '@web/blog/utils'

export default {
  components: { CategoryChip },
  props: {
    post: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    postGetAttr
  },
  computed: {
    locale() {
      return getRouteLocale()
    },
    to() {
      return {
        name: 'blog-detail',
        params: { slug: this.post.slug, locale: this.locale }
      }
    }
  }
}
</script>
