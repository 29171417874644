<template>
  <k-layout>
    <div class="tw-bg-white">
      <k-container class="tw-bg-gray-900 tw-text-gray-50 tw-py-16">
        <h1 class="tw-heading-1 tw-text-gray-50">{{ $t('blog.title') }}</h1>
        <p class="tw-mt-3.5 tw-max-w-2xl tw-heading-4 tw-text-gray-50 tw-font-normal">
          {{ $t('blog.description') }}
        </p>
      </k-container>

      <main v-if="loading" class="tw-pt-20 tw-pb-24 tw-min-h-screen">
        <k-container>
          <div class="tw-flex tw-flex-col tw-space-y-8 xl:tw-flex-row xl:tw-space-y-0 xl:tw-space-x-8">
            <div class="tw-w-full xl:tw-w-7/12">
              <lead-post-preview-skeleton />
            </div>
            <div class="tw-w-full xl:tw-w-5/12">
              <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 xl:tw-grid-cols-1 tw-gap-8">
                <new-post-preview-skeleton />
                <new-post-preview-skeleton />
                <new-post-preview-skeleton />
                <new-post-preview-skeleton />
              </div>
            </div>
          </div>
        </k-container>
        <div class="tw-px-4 tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-xl"></div>
      </main>

      <main v-else class="tw-pt-20 tw-pb-24">
        <k-container>
          <div class="tw-flex tw-flex-col tw-space-y-8 xl:tw-flex-row xl:tw-space-y-0 xl:tw-space-x-8">
            <div class="tw-w-full xl:tw-w-7/12">
              <lead-post-preview :post="leadPost" v-if="leadPost" />
            </div>
            <div class="tw-w-full xl:tw-w-5/12">
              <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 xl:tw-grid-cols-1 tw-gap-8">
                <new-post-preview :post="post" v-for="post in newPosts" :key="post.id" />
              </div>
            </div>
          </div>
        </k-container>

        <k-container>
          <h6 class="tw-heading-1 tw-mt-20">{{ $t('blog.subtitles.more_articles') }}</h6>
          <div class="mt-8 tw-w-full tw-grid tw-gap-8 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4">
            <div v-for="post in olderPosts" :key="post.id">
              <post-preview :post="post" />
            </div>
          </div>

          <k-button color="secondary" @click="morePosts">
            {{ $t('blog.actions.more_articles') }}
          </k-button>
        </k-container>
      </main>
    </div>
  </k-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import PostPreview from './../components/post-preview'
import NewPostPreview from './../components/new-post-preview'
import NewPostPreviewSkeleton from './../components/new-post-preview-skeleton'
import LeadPostPreview from './../components/lead-post-preview'
import LeadPostPreviewSkeleton from './../components/lead-post-preview-skeleton'

export default {
  components: { PostPreview, LeadPostPreview, LeadPostPreviewSkeleton, NewPostPreview, NewPostPreviewSkeleton },
  data() {
    return {
      page: 0
    }
  },
  created() {
    this.$store.dispatch('BLOG/fetchInitialPosts').then(() => {
      const page = this.toPositiveNumber(this.$route.query.p)
      if (page > 0) {
        this.$store.dispatch('BLOG/fetchNextPosts', { limit: 4 * page })
      }
    })
  },
  methods: {
    toPositiveNumber(value) {
      return value && Number.isInteger(+value) && +value > 0 ? +value : 0
    },
    morePosts() {
      this.$store.dispatch('BLOG/fetchNextPosts', { limit: 4 })
      this.$router.replace({ query: { p: this.page + 1 } })
    }
  },
  watch: {
    '$route.query.p': {
      immediate: true,
      handler(value) {
        this.page = this.toPositiveNumber(value)
      }
    }
  },
  computed: {
    ...mapGetters('BLOG', ['allPosts']),
    loading() {
      return !this.allPosts || this.allPosts.length < 1
    },
    offset() {
      return 4 * (this.page + 1)
    },
    leadPost() {
      return this.allPosts[0]
    },
    newPosts() {
      return this.allPosts.slice(1, 5)
    },
    olderPosts() {
      return this.allPosts.slice(5, 5 + this.offset)
    }
  }
}
</script>
