<template>
  <section class="tw-group">
    <router-link :to="to" class="tw-no-underline">
      <div v-if="post.featuredImage" class="tw-w-full tw-aspect-ratio-19:10 tw-rounded-sm tw-overflow-hidden">
        <img :src="post.featuredImage" class="tw-object-cover tw-object-center tw-rounded-2xl" />
      </div>
    </router-link>

    <category-chip :category="post.category" class="tw-mt-4 xl:tw-mt-8" />

    <router-link
      :to="to"
      class="tw-no-underline tw-block tw-mt-2 tw-heading-1 tw-leading-tight tw-font-bold hover:tw-underline tw-text-black"
    >
      {{ postGetAttr(post, 'title', locale) }}
    </router-link>

    <p class="tw-text-black tw-text-lg tw-mt-4">
      {{ postGetAttr(post, 'excerpt', locale) }}
    </p>

    <k-link type="other" :to="to">
      <k-button>
        {{ $t('blog.actions.read') }}
      </k-button>
    </k-link>
  </section>
</template>

<script>
import CategoryChip from './category-chip'
import { getRouteLocale } from '@web/constants/language'
import KButton from '@web/components/core/k-button'
import { postGetAttr } from '@web/blog/utils'

export default {
  components: { KButton, CategoryChip },
  props: {
    post: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    postGetAttr
  },
  computed: {
    locale() {
      return getRouteLocale()
    },
    to() {
      return {
        name: 'blog-detail',
        params: { slug: this.post.slug, locale: this.locale }
      }
    }
  }
}
</script>

<style></style>
